import { Button, Flex, Tabs, Typography } from 'antd'
import s from './s.module.css'
import { userApi } from 'entities/user'
import { useQuery } from '@tanstack/react-query'
import { FC, ReactNode } from 'react'

type ProfileInfoProps = {
    paymentsSlot?: ReactNode,
    paymentsHistorySlot?: ReactNode,
    rewardsHistorySlot?: ReactNode
}

const ProfileInfo: FC<ProfileInfoProps> = ({ paymentsSlot, rewardsHistorySlot, paymentsHistorySlot }) => {

    const { data } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        refetchInterval: 5000,
        networkMode: 'offlineFirst'
    })

    return (
        <Flex gap="middle">
            <Flex vertical gap="small">
                <img
                    src={data?.gitData?.avatar_url}
                    className={s.profile_image}
                    alt="profile"
                />
                <Button type="dashed" onClick={() => {
                    if (data?.gitData?.html_url)
                        window.location.href = data?.gitData?.html_url
                }} size="small">View on GitHub</Button>
            </Flex>
            <Flex gap="middle" vertical>
                <Typography.Title level={3} style={{ marginTop: 0 }}>{data?.gitData?.login}</Typography.Title>
                {paymentsSlot}
                <Tabs
                    style={{width: '100%'}}
                    items={[
                        {
                            key: '1',
                            label: 'My Rewards',
                            children: rewardsHistorySlot
                        },
                        {
                            key: '2',
                            label: 'Payments History',
                            children: paymentsHistorySlot
                        }
                    ]}
                />
            </Flex>
        </Flex>
    )
}
export { ProfileInfo }