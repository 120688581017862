import { createBrowserRouter } from 'react-router-dom'
import { MainLayout } from './MainLayout'
import { Feed } from 'pages/feed'
import { Main } from 'pages/main'
import { Profile } from 'pages/profile'

const appRouter = createBrowserRouter([
    {
        path: '',
        element: <Main />
    },
    {
        path: '/*',
        element: <MainLayout />,
        children: [
            {
                path: 'feed',
                element: <Feed />
            },
            {
                path: 'profile',
                element: <Profile />
            }
        ]
    },
])

export { appRouter }