/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserModel } from '../models/UserModel';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Authenticated User
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public getAuthenticatedUserApiUsersMeGet(): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/me',
            errors: {
                401: `Unauthorized`,
            },
        });
    }
    /**
     * Get User By Id
     * @returns UserModel Successful Response
     * @throws ApiError
     */
    public getUserByIdApiUsersUserIdGet({
        userId,
    }: {
        userId: string,
    }): CancelablePromise<UserModel> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/users/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                401: `Unauthorized`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }
}
