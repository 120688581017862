import { appApi } from 'shared/api/app.api'
import { CreateRewardRequest } from 'shared/gen/models/CreateRewardRequest'


class _RewardsApi {

    async createNewReward(opts: { issue_url: string, amount: number }) {
        try {
            const resp = appApi.rewards.createRewardApiRewardsPost({
                requestBody: {
                    issue_html_url: opts.issue_url,
                    sats: opts.amount
                } as CreateRewardRequest
            })
            return resp
        }
        catch (e) {
            throw new Error(e as any)
        }
    }

    async getRewards(opts: { issueId?: string, rewarderId?: string, winnerId?: string, open?: boolean, skip?: number, limit?: number }) {
        try {
            const resp = appApi.rewards.listRewardsApiRewardsGet({
                issueId: opts.issueId,
                rewarderId: opts.rewarderId,
                winnerId: opts.winnerId,
                open: opts.open,
                skip: opts.skip,
                limit: opts.limit
            })
            return resp
        }
        catch (e) {
            throw new Error(e as any)
        }
    }

}

const rewardsApi = new _RewardsApi()

export { rewardsApi }