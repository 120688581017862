/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AuthService } from './services/AuthService';
import { RewardsService } from './services/RewardsService';
import { UserService } from './services/UserService';
import { WalletsService } from './services/WalletsService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class lb {
    public readonly auth: AuthService;
    public readonly rewards: RewardsService;
    public readonly user: UserService;
    public readonly wallets: WalletsService;
    public readonly request: BaseHttpRequest;
    constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
        this.request = new HttpRequest({
            BASE: config?.BASE ?? '',
            VERSION: config?.VERSION ?? '0.1.0',
            WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
            CREDENTIALS: config?.CREDENTIALS ?? 'include',
            TOKEN: config?.TOKEN,
            USERNAME: config?.USERNAME,
            PASSWORD: config?.PASSWORD,
            HEADERS: config?.HEADERS,
            ENCODE_PATH: config?.ENCODE_PATH,
        });
        this.auth = new AuthService(this.request);
        this.rewards = new RewardsService(this.request);
        this.user = new UserService(this.request);
        this.wallets = new WalletsService(this.request);
    }
}

