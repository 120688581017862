import { CheckOutlined } from '@ant-design/icons'
import { useMutation } from '@tanstack/react-query'
import { Button, Flex, Form, Input, Popover } from 'antd'
import { feedApi } from 'entities/feed/api/feed.api'
import { FC } from 'react'

type FeedCheckPullProps = {
    owner_name: string,
    repo_name: string
}

const FeedCheckPull: FC<FeedCheckPullProps> = ({ owner_name, repo_name }) => {

    const { mutateAsync: checkPull, isPending } = useMutation({ mutationFn: feedApi.checkPull })

    const checkPullHandler = async (pullNumber: number) => {
        try {
            await checkPull({
                repo_name: repo_name,
                repo_owner: owner_name,
                pull_request_number: pullNumber
            })
        }
        catch (e) {
            // TODO: add notify
        }
    }

    return (
        <Popover
            destroyTooltipOnHide={true}
            content={
                <Form
                    initialValues={{
                        repo: owner_name + '/' + repo_name
                    }}
                    onFinish={(e) => {
                        checkPullHandler(e.number)
                    }}
                >
                    <Flex vertical gap="small">
                        <Form.Item
                            name="repo"
                            rules={[
                                {
                                    required: true,
                                    message: 'Required field'
                                }
                            ]}
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Flex gap="small">
                            <Form.Item
                                name="number"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required field'
                                    }
                                ]}
                            >
                                <Input placeholder="Pull request number" />
                            </Form.Item>
                            <Button loading={isPending} type="primary" htmlType="submit">Submit</Button>
                        </Flex>
                    </Flex>
                </Form>
            }
        >
            <Button
                icon={<CheckOutlined />}
                onClick={() => { }}>
                Check Pull
            </Button>
        </Popover>
    )
}
export { FeedCheckPull }