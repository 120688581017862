import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'


type FeedStore = {
    // cluster for vars and func`s
    currentPage: number,
    pageSize: number,
    searchPhrase: string,
    setPage: (page: number) => void,
    setPageSize: (pageSize: number) => void,
    setSearchPhrase: (phrase: string) => void,
    resetStore: () => void
}

const useFeedStore = create<FeedStore>()(
    immer(
        (set) => ({
            currentPage: 1,
            setPage: (page: number) => {
                set(state => {
                    state.currentPage = page
                })
            },
            pageSize: 20,
            setPageSize: (pageSize: number) => {
                set(state => {
                    state.pageSize = pageSize
                })
            },
            searchPhrase: '',
            setSearchPhrase: (phrase: string) => {
                set(state => {
                    state.searchPhrase = phrase
                })
            },
            resetStore: () => {
                // TODO: add reset func
            }
        })
    )
)

export { useFeedStore }
export type { FeedStore }
