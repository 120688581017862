import { Flex } from 'antd'
import { FeedPagination, CreateNewReward } from 'features/feed'
import { FeedList } from 'widgets/feed'

const Feed = () => {

    return (
        <>
            <Flex
                vertical
                gap="large"
                style={{
                    padding: '40px',
                    width: '800px',
                    margin: '0 auto'
                }}>
                <CreateNewReward />
                <FeedList />
                <FeedPagination />
            </Flex>
        </>
    )
}
export { Feed }