import { FC, ReactNode, useRef, useState } from 'react'
import type { TourProps } from 'antd'
import { Button, Tour } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import s from './s.module.css'

type GuideLabelProps = {
    className?: string,
    guideData?: {
        title?: ReactNode,
        body?: ReactNode
    }
}

const GuideLabel: FC<GuideLabelProps> = ({
    className,
    guideData
}) => {

    const [open, setOpen] = useState<boolean>(false)
    const ref = useRef(null)
    const steps: TourProps['steps'] = [
        {
            // TODO: add data
            title: 'Upload File', // guideData.title
            description: 'Put your files here.', // guideData.body
            target: () => ref.current,
        }
    ]

    return (
        <>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
            <Button
                className={className}
                ref={ref}
                size="small"
                type="text"
                onClick={() => setOpen(true)}
                icon={<QuestionCircleOutlined className={`${s.questionCircle}`} />}
            />
        </>
    )
}
export { GuideLabel }