import { Card, Col, Flex, Row, Typography } from 'antd'
import { FC, ReactNode } from 'react'
import { RewardExpandedModel } from 'shared/gen'
import s from './FeedCard.module.css'
import { ProfileImage } from 'shared/ui/ProfileImage'
import { Link } from 'react-router-dom'
import { Price } from 'shared/ui/Price'

type FeedCardProps = {
    data: RewardExpandedModel,
    pullSlot?: ReactNode,
    winnerBorder?: boolean,
    guideSlot?: ReactNode
}

const FeedCard: FC<FeedCardProps> = ({ data, pullSlot, winnerBorder, guideSlot }) => {

    return (
        <Card
            bordered={false}
            className={`${s.card} ${winnerBorder ? s.green : ''}`}
        >
            <span className={s.guide}>
                {guideSlot}
            </span>
            <Typography className="opacity50">{data.issue_repo_owner}/{data.issue_repo_name}/{data.issue_number}</Typography>
            <Row gutter={12}>
                <Col span={16}>
                    <Flex vertical gap="small">
                        <Typography.Title level={4} style={{ marginTop: 0 }}>{data?.issue_title}</Typography.Title>
                        <Flex vertical gap="small">
                            <Link
                                target="_blank"
                                to={'https://github.com/' + data.rewarder_github_username}
                            >
                                <ProfileImage image_url={data?.rewarder_avatar_url ?? ''} withList={2} />
                            </Link>
                            <Typography>
                                <Link
                                    target="_blank"
                                    to={'https://github.com/' + data.rewarder_github_username}
                                >
                                    {data.rewarder_github_username}
                                </Link>
                                &nbsp; {data.total_rewards > 1 ? + `${data.total_rewards} others` : ''}
                            </Typography>
                        </Flex>
                        <Typography className="opacity50">{new Date(data.created_at).toLocaleString()}</Typography>
                    </Flex>
                </Col>
                <Col span={8}>
                    <Flex vertical gap="small" justify="space-between" style={{ height: '100%' }}>
                        {
                            data.winner_id
                                ? <Flex gap="small" vertical>
                                    <Typography.Title level={5} style={{ marginTop: 0 }}>Winner: </Typography.Title>
                                    <Link
                                        target="_blank"
                                        to={'https://github.com/' + data.winner_github_username}
                                    >
                                        <ProfileImage image_url={data.winner_avatar_url ?? ''} />
                                    </Link>
                                    <Typography>
                                        <Link
                                            target="_blank"
                                            to={'https://github.com/' + data.winner_github_username}
                                        >
                                            {data.winner_github_username}
                                        </Link>
                                    </Typography>
                                </Flex>
                                : pullSlot
                        }
                        <Flex align="center" gap="small">
                            <Typography className="opacity50">Reward: </Typography>
                            <Price amount={data.total_reward_sats} />
                        </Flex>
                    </Flex>

                </Col>
            </Row>
        </Card>
    )
}
export { FeedCard }