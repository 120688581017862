import { appApi } from "shared/api/app.api"
import { gitHubApi } from 'shared/api/github.api'
import { LightningWalletBalance } from 'shared/gen'

class _UserApi {
  apiKey = ["_UserApi"]

  userId: string | undefined = undefined

  getUserId(){
    return this.userId
  }

  getAuthToken() {
    return localStorage.getItem("accessToken")
  }

  setAuthToken(token?: string) {
    token
      ? localStorage.setItem("accessToken", "Bearer " + token)
      : localStorage.removeItem("accessToken")
  }

  resetTokens() {
    localStorage.removeItem("accessToken")
    localStorage.removeItem("gitHubToken")
  }

  getGitHubToken() {
    return localStorage.getItem("gitHubToken")
  }

  setGitHubToken(token?: string) {
    token
      ? localStorage.setItem("gitHubToken", "Bearer " + token)
      : localStorage.removeItem("gitHubToken")
  }

  async authWithGithubToken(githubAuthToken: string) {
    try {
      const { access_token } =
        await appApi.auth.githubCallbackApiAuthGithubCallbackGet({
          code: githubAuthToken,
        })
      return access_token
    } catch (e) {
      throw new Error(e as any)
    }
  }

  async getUserData() {
    try {
      const user = await appApi.user.getAuthenticatedUserApiUsersMeGet()
      this.userId = user.id
      if (!user) return undefined

      const { data: userGitHubData } = await gitHubApi.get<{
        "login": string,
        "id": number,
        "avatar_url": string,
        "html_url": string,
      } | undefined>('/user/' + user.github_id, {
        headers: {
          'Authorization': userApi.getGitHubToken()
        }
      })

      let userWalletBalance: LightningWalletBalance | undefined
      try {
        userWalletBalance =
          await appApi.wallets.getLightningWalletApiWalletsLightningGet()
      } catch (e) {
        await appApi.wallets.createWalletApiWalletsLightningPost()
        userWalletBalance =
          await appApi.wallets.getLightningWalletApiWalletsLightningGet()
      }

      return {
        userData: user,
        gitData: userGitHubData,
        sats: userWalletBalance,
      }
    } catch (e) { }
  }
}

const userApi = new _UserApi()

export { userApi }
