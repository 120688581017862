import { FC } from 'react'
import s from './s.module.css'

type ProfileImageProps = {
    image_url?: string,
    withList?: number,
    size?: 'big' | 'normal'
}

const ProfileImage: FC<ProfileImageProps> = ({ image_url, withList, size }) => {

    const withListElements = new Array(withList ?? 0).fill(1).map((item, index) =>
        <div
            key={index}
            className={s.more}
            style={{
                zIndex: (index + 2) * -1,
                transform: 'translateX(' + index * 10 + 'px) translateY(-50%) scale(' + (1 - (index * 10) / 100) + ')',
                backgroundColor: 'rgba(0,0,0, ' + (0.3 - (index) * 20 / 100) + ')'
            }} />)

    return (
        <span className={s.box}>
            <div
                className={`${s.image} ${s['size_' + size]}`}
                style={{ backgroundImage: `url(${image_url})` }}
            />
            {
                withListElements
            }
        </span>

    )
}
export { ProfileImage }