import { ConfigProvider } from 'antd'
import './index.css'
import { RouterProvider } from 'react-router-dom'
import { appRouter } from './Router'
import { darkTheme } from 'shared/themes/dark'
import { useEffect } from 'react'
import { setAuthInterceptor } from 'entities/user'
import { apiInstance } from 'shared/api/app.api'
import { userApi } from 'entities/user/api/user.api'
import { gitHubApi } from 'shared/api/github.api'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const client = new QueryClient()

const App = () => {

    useEffect(() => {
        setAuthInterceptor(apiInstance, userApi.getAuthToken)
        setAuthInterceptor(gitHubApi, userApi.getGitHubToken)
    }, [])

    return (
        <QueryClientProvider client={client}>
            <ConfigProvider theme={darkTheme}>
                <RouterProvider router={appRouter} />
            </ConfigProvider>
        </QueryClientProvider>
    )
}
export { App }