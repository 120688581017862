import { userApi } from 'entities/user/api/user.api'
import { appApi } from 'shared/api/app.api'
import { gitHubApi } from 'shared/api/github.api'
import { UserModel } from 'shared/gen/models/UserModel'
import { GetFeedRequestBody } from '../model/feedApi.types'
import { FetchPullRequestDTO } from 'shared/gen'

class _FeedApi {

    apiKey = ['_FeedApi']

    qkGetIssueData(issueUrl: string) {
        return [this.apiKey, 'getIssueData', issueUrl]
    }

    async getIssueData(issueUrl: string): Promise<{
        title: string,
        user: {
            "login": string,
            "avatar_url": string,
            "html_url": string,
        }
    } | undefined> {
        try {
            const { data } = await gitHubApi.get(issueUrl, {
                headers: {
                    'Authorization': userApi.getGitHubToken()
                }
            })
            return data
        } catch (e) {
            return
        }
    }

    qkGetGitHubAccountDataByAppUserId(appUserId: string) {
        return [this.apiKey, 'getGitHubAccountDataByAppUserId', appUserId]
    }

    async getGitHubAccountDataByAppUserId(appUserId: string): Promise<{
        "login": string,
        "id": number,
        "avatar_url": string,
        "html_url": string,
    } | undefined> {
        try {
            const resp = await appApi.user.getAuthenticatedUserApiUsersMeGet() as UserModel | undefined
            if (!resp)
                return

            const { data } = await gitHubApi.get('/user/' + resp.github_id, {
                headers: {
                    'Authorization': userApi.getGitHubToken()
                }
            })
            return data
        } catch (e) {
            return
        }
    }

    qkGetGitHubAccountDataByGitHubUserId(appUserId: string) {
        return [this.apiKey, 'getGitHubAccountDataByGitHubUserId', appUserId]
    }

    async getGitHubAccountDataByGitHubUserId(gitUserId: string): Promise<{
        "login": string,
        "id": number,
        "avatar_url": string,
        "html_url": string,
    } | undefined> {
        try {
            const { data } = await gitHubApi.get('/user/' + gitUserId, {
                headers: {
                    'Authorization': userApi.getGitHubToken()
                }
            })
            return data
        } catch (e) {
            return
        }
    }

    qkGetFeedList(opts: GetFeedRequestBody) {
        return [this.apiKey, 'getFeedList', opts]
    }

    async getFeedList(opts: GetFeedRequestBody) {
        try {
            return await appApi.rewards.listRewardsApiRewardsGet({
                skip: opts.skip,
                limit: opts.limit
            })
        } catch (e) {
            return []
        }
    }

    qkGetFeedTotalCount() {
        return [this.apiKey, 'getFeedList']
    }

    async getFeedTotalCount() {
        try {
            return await appApi.rewards.getRewardsCountApiRewardsCountGet({})
        } catch (e) {
            return {
                count: 0
            }
        }
    }

    async checkPull(data: FetchPullRequestDTO) {
        try {
            return await appApi.rewards.checkPullRequestApiRewardsCheckPullPost({
                xGithubToken: (localStorage.getItem('gitHubToken') as string).split(' ')[1] ?? '',
                requestBody: data
            })
        }
        catch (e) {

        }
    }
}

const feedApi = new _FeedApi()

export { feedApi }