import { Flex } from 'antd'
import Typography from 'antd/es/typography/Typography'
import { FC } from 'react'
import { SatsIcon } from 'shared/icons/SatsIcon'
import s from './s.module.css'


type PriceProps = {
    amount?: number,
    size?: 'small' | 'normal' | 'big'
}
const Price: FC<PriceProps> = ({ amount, size = 'normal' }) => {

    return (
        <Flex className={s['size_' + size]} align="center" gap="small">
            <Typography className={s['size_' + size]}>{amount}</Typography>
            <SatsIcon className={s['size_' + size]} />
        </Flex>
    )
}
export { Price }
