import { InfoCircleOutlined } from '@ant-design/icons'
import { Flex, Typography } from 'antd'
import { FC, ReactNode } from 'react'
import s from './s.module.css'

type WarningMessageProps = {
    message?: ReactNode
}

const WarningMessage: FC<WarningMessageProps> = ({ message }) => {
    return (
        <Flex gap="small" align="start" className={s.box}>
            <InfoCircleOutlined className={s.box_icon} />
            <Typography>{message}</Typography>
        </Flex>
    )
}
export { WarningMessage }