import axios from 'axios'
import { CancelablePromise, lb } from 'shared/gen'
import { ApiRequestOptions } from 'shared/gen/core/ApiRequestOptions'
import { AxiosHttpRequest } from 'shared/gen/core/AxiosHttpRequest'
import { request } from 'shared/gen/core/request'

const apiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
})

const appApi = new lb(
    {},
    class AxiosHttpRequestInstance extends AxiosHttpRequest {
        public override request<T>(options: ApiRequestOptions): CancelablePromise<T> {
            return request(this.config, options, apiInstance)
        }
    },
)

export { appApi }
export { apiInstance }