import { Flex } from 'antd'
import {
    ProfileInfo,
    ProfilePayments,
    ProfilePaymentsHistory,
    ProfileRewardsHistory
} from 'widgets/profile'


const Profile = () => {

    return (
        <Flex vertical gap="large" style={{ padding: '40px', width: '1000px', margin: '0 auto' }}>
            <ProfileInfo
                paymentsSlot={<ProfilePayments />}
                rewardsHistorySlot={<ProfileRewardsHistory />}
                paymentsHistorySlot={<ProfilePaymentsHistory />}
            />
        </Flex>
    )
}
export { Profile }
