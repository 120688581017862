import { useQuery } from '@tanstack/react-query'
import { Flex, Tooltip, Typography } from 'antd'
import { userApi } from 'entities/user'
import { UserDeposit, UserWithdraw } from 'features/user'
import { Price } from 'shared/ui/Price'

const ProfilePayments = () => {

    const { data } = useQuery({
        queryKey: ['userDataFetching'],
        queryFn: () => userApi.getUserData(),
        refetchInterval: 5000,
        networkMode: 'offlineFirst'
    })

    return (
        <Flex vertical gap="small">
            <Flex gap="small" align="center">
                <Typography>Balance: </Typography>
                <Price amount={data?.sats.total_sats} />
                /
                <Tooltip title="reserved">
                    <span className="opacity50">
                        <Price amount={data?.sats.reserved_sats} />
                    </span>
                </Tooltip>
            </Flex>
            <Flex gap="small">
                <UserDeposit />
                <UserWithdraw />
            </Flex>
        </Flex>
    )
}
export { ProfilePayments }