import { useQuery } from '@tanstack/react-query'
import { Flex, Spin, Typography } from 'antd'
import { PaymentHistoryCard } from 'entities/user'
import { appApi } from 'shared/api/app.api'

const ProfilePaymentsHistory = () => {

    const { data, isLoading } = useQuery({
        queryKey: ['historyQuery'],
        queryFn: () => appApi.wallets.getWalletHistoryApiWalletsLightningHistoryGet({}),
        refetchInterval: 5000
    })

    return (
        <Flex gap="small" vertical>
            {
                isLoading
                    ? <Spin />
                    : data?.length // TODO: add id
                        ? data && [...data].reverse().map((item) => <PaymentHistoryCard key={item.checking_id} {...item} />)
                        : <Typography className="opacity50">No user payments history</Typography>
            }
        </Flex>
    )
}
export { ProfilePaymentsHistory }